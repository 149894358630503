import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './components/card';
import { Button } from './components/button';
import { Badge } from './components/badge';
import { Switch } from './components/switch';
import Header from './Header';
import Faq from './Faq';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const PricingSection = () => {
  const [isYearly, setIsYearly] = useState(true);
  const monthlyPrice = 4.99;
  const yearlyPrice = 2.99;
  const yearlyTotal = yearlyPrice * 12;
  const monthlySavings = ((monthlyPrice * 12) - yearlyTotal).toFixed(2);

  return (
    <>
        <Header />
        <div className="w-full py-12 px-4">
        <div className="max-w-6xl mx-auto">
            <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Simple, transparent pricing</h2>
            <div className="flex flex-col items-center justify-center gap-2">
                <div className='flex items-center justify-center gap-3 mt-6'>
                    <span className={`${!isYearly ? 'font-bold' : 'text-gray-500'}`}>Monthly</span>
                    <Switch
                    checked={isYearly}
                    onCheckedChange={setIsYearly}
                    />
                    <span className={`${isYearly ? 'font-bold' : 'text-gray-500'}`}>Yearly</span>
                </div>
            </div>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
                <Card className="relative border-2 h-full flex flex-col">
                    <CardHeader>
                        <CardTitle>Free</CardTitle>
                        <CardDescription>Perfect to get started</CardDescription>
                        <div className="mt-4">
                            <span className="text-3xl font-bold">$0</span>
                            <span className="text-gray-500 dark:text-gray-400">/month</span>
                        </div>
                    </CardHeader>
                    <CardContent className="flex-1 flex flex-col">
                        <ul className="space-y-3 flex-1">
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />3 folders</li>
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Save up to 20 prompts</li>
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Basic conversation export</li>
                        </ul>
                        <Link to={"https://chromewebstore.google.com/detail/gptorganizer/nnjjgiijakdfccebgfeeceopgbdcflob"} target='_blank'>
                            <Button variant="ghost" className="w-full mt-6 border border-gray-200">Get Started</Button>
                        </Link>
                    </CardContent>
                </Card>

                <Card className="relative border-2 h-full flex flex-col">
                    <Badge className="absolute -top-3 right-4 bg-black text-white dark:bg-white dark:text-black">MOST POPULAR</Badge>
                    <CardHeader>
                    <CardTitle>Premium</CardTitle>
                    <CardDescription>For power users</CardDescription>
                    <div className="mt-4 space-y-2">
                        <div>
                        <span className="text-3xl font-bold">${isYearly ? yearlyPrice : monthlyPrice}</span>
                        <span className="text-gray-500 dark:text-gray-400">/{isYearly ? 'month' : 'month'}</span>
                        </div>
                        {isYearly && (
                        <div className="text-sm">
                            <span className="line-through text-gray-400">${(monthlyPrice * 12).toFixed(2)}</span>
                            <span className="ml-2 text-green-500">${yearlyTotal.toFixed(2)}/year</span>
                        </div>
                        )}
                    </div>
                    </CardHeader>
                    <CardContent>
                    <ul className="space-y-3">
                        <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Unlimited folders</li>
                        <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Unlimited prompts</li>
                        <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Advanced export</li>
                        <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Priority support</li>
                        <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Custom organization</li>
                    </ul>
                    <Link to={`${isYearly ? "https://buy.stripe.com/00gcPS6du0OHgXCaEJ" : "https://buy.stripe.com/28o6rueK0aph0YEfZ4"}`} target='_blank'>
                        <Button className="w-full mt-6 text-white">Get Premium</Button>
                    </Link>
                    </CardContent>
                </Card>

                <Card className="relative border-2 h-full flex flex-col">
                    <Badge className="absolute -top-3 right-4 text-white">LIMITED TIME</Badge>
                    <CardHeader>
                        <CardTitle>Lifetime</CardTitle>
                        <CardDescription>Best long-term value</CardDescription>
                        <div className="mt-4 space-y-2">
                            <div>
                                <span className="text-3xl font-bold">$49.99</span>
                                <span className="text-gray-500 dark:text-gray-400">/lifetime</span>
                            </div>
                            <div className="text-sm">
                                <span className="line-through text-gray-400">$199</span>
                                <span className="ml-2 text-green-500">Save $149</span>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="flex-1 flex flex-col">
                        <ul className="space-y-3 flex-1">
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />All Premium features</li>
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />One-time payment</li>
                            <li className="flex items-center"><Check className="h-4 w-4 text-green-500 mr-2" />Lifetime updates</li>
                        </ul>
                        <Link to={"https://buy.stripe.com/3cs5nqatKgNFgXC6os"} target='_blank'>
                            <Button variant="ghost" className="w-full mt-6 border border-gray-200">Get Lifetime Access</Button>
                        </Link>
                    </CardContent>
                </Card>
            </div>
        </div>
        </div>
        <Faq />
        <Footer />
    </>
  );
};

export default PricingSection;