"use client";
import React, { useState, useEffect } from "react";
import { Button } from "./components/button";
import { ChevronRight, Github, Play, X, XIcon } from "lucide-react";
import { Link, Navigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTrigger } from "./components/dialoglandingpage";
import { DialogClose } from "./components/dialog";
import PerspectiveEffect from "./components/perspective";
import { BorderBeam } from "./components/border-beam";
import { cn } from "./lib/utils";
import Marquee from "./components/marquee";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/accordion";
import { Menu } from "lucide-react";
import { BackgroundLines } from "./components/background-lines";
import Header from "./Header";
import Faq from "./Faq";
import Footer from "./Footer";


const reviews = [
    {
      name: "Olivia Parker",
      username: "@OliviaBuilds",
      body: "This tool completely transformed the way I use ChatGPT. Pinning messages and saving prompts has made my workflow so much more efficient!",
      img: "/1.jpeg",
    },
    {
      name: "Noah Harris",
      username: "@NoahCodes",
      body: "I love how easy it is to organize my chats into folders. Everything is so intuitive, and it keeps my work and ideas perfectly structured.",
      img: "/2.jpg",
    },
    {
      name: "Ethan Walker",
      username: "@EthanDev",
      body: "As someone who relies heavily on prompts, having a dedicated space to save and manage them has been a game-changer for my productivity.",
      img: "/3.png",
    },
    {
      name: "Charlotte Green",
      username: "@CharlotteUX",
      body: "Pinning key messages and organizing my chats makes using ChatGPT feel so much more seamless. It’s a must-have for anyone who uses AI regularly.",
      img: "/4.jpeg",
    },
    {
      name: "Emily Carter",
      username: "@EmilyDesigns",
      body: "I didn’t know I needed this until I tried it. Saving my favorite prompts and accessing them anytime has made my creative process so much smoother!",
      img: "/5.jpeg",
    },
    {
      name: "Alex Brown",
      username: "@CodeWithAlex",
      body: "The ability to pin important messages and organize everything in folders is exactly what I’ve been missing. It’s like having a personal assistant for ChatGPT!",
      img: "/6.jpg",
    },
  ];
  

const firstRow = reviews.slice(0, reviews.length / 3);
const secondRow = reviews.slice(reviews.length / 3, (2 * reviews.length) / 3);
const thirdRow = reviews.slice((2 * reviews.length) / 3);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative w-96 md:w-full cursor-pointer h-full overflow-hidden rounded-xl border p-6",
      )}
    >
      <div className="flex flex-row items-center gap-2 pb-3">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm line-clamp-3">{body}</blockquote>
    </figure>
  );
};

export default function LandingPage() {
    const [dialogOpened, setDialogOpened] = useState<boolean>(false)
    const [browserName, setBrowserName] = useState('Browser');

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Firefox')) {
            setBrowserName('Firefox');
        } else {
            setBrowserName('Chrome');
        }
    }, []);

    return (
        <>
        <Dialog open={dialogOpened} onOpenChange={setDialogOpened}>
            <DialogContent className="w-full flex h-screen border-none rounded-none items-start">
            <div className="relative size-full pt-[55%]">
                <iframe className="absolute top-0" width="100%" height="100%" src="https://www.youtube.com/embed/dEsvfYdLmJw?autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            <DialogClose className="focus:outline-none outline-none">
                <Button>
                <X />
                </Button>
            </DialogClose>
            </DialogContent>
        </Dialog>
        <div>
            <Header />
            <BackgroundLines>
                <section className="w-full rounded-md relative">
                    <div className="mx-auto px-8 sm:px-6 lg:px-8 gap-5 pt-28 flex flex-col text-center max-w-7xl">
                        <h1 className="relative z-10 text-5xl md:text-6xl lg:text-7xl bg-clip-text text-transparent bg-gradient-to-b from-black to-neutral-600  text-center font-sans font-bold">
                            Easily organize and access your ChatGPT conversations
                        </h1>
                        <p className="text-neutral-500 max-w-3xl mx-auto my-2 text-sm sm:text-md md:text-lg lg:text-xl text-center relative z-10">
                            Create folders, save your prompts, pin your messages: your conversational assistant, structured like never before.
                        </p>
                        <div className="flex flex-col items-center justify-center sm:flex-row z-10 mt-4 space-y-4 sm:space-y-0">
                            {browserName === 'Firefox' ? (
                                <Link to={"https://addons.mozilla.org/firefox/addon/gptorganizer/"} target="_blank">
                                    <Button className="h-12 font-semibold group cursor-pointer w-fit text-lg md:h-14 sm:px-10 rounded-md px-8 text-white">
                                        Add to Firefox
                                    </Button>
                                </Link>
                            ) : (
                                <Link to={"https://chromewebstore.google.com/detail/gptorganizer/nnjjgiijakdfccebgfeeceopgbdcflob"} target="_blank">
                                    <Button className="h-12 font-semibold group cursor-pointer w-fit text-lg md:h-14 sm:px-10 rounded-md px-8 text-white">
                                    Add to Chrome</Button>
                                </Link>
                            )}
                            <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank" className="h-14 font-semibold flex items-center text-lg px-8">
                                Join Our Community
                            </Link>
                        </div>
                    </div>
                </section>
            </BackgroundLines>
            <section className="flex justify-center pt-10">
            <PerspectiveEffect className="max-w-5xl rounded-xl relative hidden md:block">
                <video width="2000" height="698" autoPlay muted playsInline loop poster="/folderfeature.png" className="rounded-xl border-8 shadow-default duration-500 animate-in fade-in">
                    <source src="/1204.mp4" type="video/mp4" />
                </video>
                <button
                    onClick={() => setDialogOpened(true)}
                    className="focus:outline-none outline-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 size-24 rounded-full sm:size-28 bg-primary/60 flex items-center justify-center backdrop-blur-sm transition-all duration-300 ease-in-out hover:scale-[0.8]"
                >
                    <Play stroke="#ffffff" className="fill-white size-10"/>   
                </button>
                <BorderBeam />
            </PerspectiveEffect>
            <div className="relative rounded-md bg-background sm:mt-8 md:hidden px-10">
                <video width="2000" height="698" autoPlay muted playsInline loop poster="/folderfeature.png" className="rounded-md border-default shadow-default duration-500 animate-in fade-in">
                    <source src="/1204.mp4" type="video/mp4" />
                </video>
            </div>
            </section>
            <section className="px-10 md:px-8 flex flex-col gap-5 items-center justify-center pt-16 pb-7">
                <h2 className="max-w-4xl text-balance text-center font-heading text-3xl font-medium tracking-tighter sm:text-5xl lg:text-6xl">Features designed with you in mind</h2>
                <p className="text-neutral-500 max-w-3xl mx-auto my-2 text-sm sm:text-md md:text-lg lg:text-xl text-center relative z-10">Tools designed to simplify, organize and maximize your experience.</p>
                <div className="mt-14 grid cols-1 md:grid-cols-5 gap-4 md:auto-rows-[25rem] max-w-7xl mx-auto">
                    <div className="group isolate rounded-2xl border shadow-[0_1px_1px_rgba(0,0,0,0.05),0_4px_6px_rgba(34,42,53,0.04),0_24px_68px_rgba(47,48,55,0.05),0_2px_3px_rgba(0,0,0,0.04)] overflow-hidden flex flex-col justify-between md:col-span-3">
                    <div className="p-6 h-40">
                        <h3 className="font-sans text-base font-medium tracking-tight text-foreground">Keep Your Essential Messages Close at Hand</h3>
                        <p className="font-sans max-w-xs text-base font-normal tracking-tight mt-2 text-neutral-500 dark:text-neutral-400">Pin important messages so you can access them quickly, without losing the thread.</p>
                    </div>
                    <div className="overflow-hidden relative w-full h-full">
                        <div 
                        className="w-full h-full p-4 group-hover:p-1 rounded-lg border bg-neutral-800 border-neutral-700 ml-6 transition-all group-hover:ml-2 group-hover:mt-1 mt-2">
                            <div className="h-[300px]">
                                <img alt="Dashboard" loading="lazy" width="1000" height="1000" decoding="async" data-nimg="1" className="w-full h-full bg-red-500 object-cover rounded-lg " src="/pinnfeature.png" />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="group isolate rounded-2xl border shadow-[0_1px_1px_rgba(0,0,0,0.05),0_4px_6px_rgba(34,42,53,0.04),0_24px_68px_rgba(47,48,55,0.05),0_2px_3px_rgba(0,0,0,0.04)] overflow-hidden flex flex-col justify-between md:col-span-2">
                    <div className="p-6 h-40">
                        <h3 className="font-sans text-base font-medium tracking-tight text-foreground">An Organization Like You</h3>
                        <p className="font-sans max-w-xs text-base font-normal tracking-tight mt-2 text-neutral-500 dark:text-neutral-400">Organize your chats in customized folders for intuitive navigation.</p>
                    </div>
                    <div className="overflow-hidden relative w-full h-full">
                        <div
                        className="w-full h-full p-4 group-hover:p-1 rounded-lg border bg-neutral-800 border-neutral-700 ml-6 transition-all group-hover:ml-2 group-hover:mt-1 mt-2">
                            <div className="h-[300px]">
                                <img alt="Dashboard" loading="lazy" width="1000" height="700" decoding="async" data-nimg="1" className="w-full h-full object-cover rounded-lg " src="/folderfeature.png" />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="group isolate rounded-2xl border shadow-[0_1px_1px_rgba(0,0,0,0.05),0_4px_6px_rgba(34,42,53,0.04),0_24px_68px_rgba(47,48,55,0.05),0_2px_3px_rgba(0,0,0,0.04)] overflow-hidden flex flex-col justify-between md:col-span-2">
                    <div className="p-6 h-40">
                        <h3 className="font-sans text-base font-medium tracking-tight text-foreground">Your Best Prompts, Always Accessible</h3>
                        <p className="font-sans max-w-xs text-base font-normal tracking-tight mt-2 text-neutral-500">Save your favorite prompts and reuse them at the click of a button.</p>
                    </div>
                    <div className="overflow-hidden relative w-full h-full">
                        <div
                        className="w-full h-full p-4 group-hover:p-1 rounded-lg border bg-neutral-800 border-neutral-700 ml-6 transition-all group-hover:ml-2 group-hover:mt-1 mt-2">
                            <div className="h-[300px]">
                                <img alt="Dashboard" loading="lazy" width="500" height="500" decoding="async" data-nimg="1" className="w-full h-full object-cover rounded-lg " src="/promptfeature.png" />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="group isolate rounded-2xl border shadow-[0_1px_1px_rgba(0,0,0,0.05),0_4px_6px_rgba(34,42,53,0.04),0_24px_68px_rgba(47,48,55,0.05),0_2px_3px_rgba(0,0,0,0.04)] overflow-hidden flex flex-col justify-between md:col-span-3">
                    <div className="p-6 h-40">
                        <h3 className="font-sans text-base font-medium tracking-tight text-foreground">Export to Notion</h3>
                        <p className="font-sans max-w-xs text-base font-normal tracking-tight mt-2 text-neutral-500">Seamlessly Transfer Your Chats to Notion for Better Organization.</p>
                    </div>
                    <div className="overflow-hidden relative w-full h-full">
                        <div 
                        className="w-full h-full p-4 group-hover:p-1 rounded-lg border bg-neutral-800 border-neutral-700 ml-6 transition-all group-hover:ml-2 group-hover:mt-1 mt-2">
                            <div className="h-[250px]">
                                <img alt="Dashboard" loading="lazy" width="1000" height="700" decoding="async" data-nimg="1" className="w-full h-full object-cover rounded-lg " src="/export.png" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="px-1 md:px-8 flex flex-col gap-5 items-center justify-center py-24">
                <h2 className="max-w-4xl text-balance text-center font-heading text-3xl font-medium tracking-tighter sm:text-5xl lg:text-6xl">What people are saying</h2>
                <p className="text-neutral-500 max-w-3xl mx-auto my-2 text-sm sm:text-md md:text-lg lg:text-xl text-center relative z-10">Read our reviews and testimonials.</p>
                <div className="relative mt-14 h-[500px] w-full hidden md:flex flex-row items-center justify-center overflow-hidden rounded-lg bg-background">
                    <Marquee pauseOnHover vertical className="[--duration:20s]">
                    {firstRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <Marquee reverse pauseOnHover vertical className="[--duration:20s]">
                    {secondRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <Marquee pauseOnHover vertical className="[--duration:20s]">
                    {thirdRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <div className="pointer-events-none absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-white"></div>
                    <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-white"></div>
                </div>
                <div className="relative mt-14 h-[500px] w-full flex flex-col md:hidden items-center justify-center overflow-hidden rounded-lg bg-background">
                    <Marquee pauseOnHover className="[--duration:20s]">
                    {firstRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <Marquee reverse pauseOnHover className="[--duration:20s]">
                    {secondRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <Marquee pauseOnHover className="[--duration:20s]">
                    {thirdRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                    </Marquee>
                    <div className="pointer-events-none absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-background"></div>
                    <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-background"></div>
                </div>
            </section>
            <Faq />
            <section className="px-1 md:px-8 flex flex-col gap-5 items-center justify-center py-24">
                <h2 className="max-w-4xl text-balance text-center font-heading text-3xl font-medium tracking-tighter sm:text-5xl">Ready to organize your ChatGPT experience?
                </h2>
                <p className="max-w-2xl text-balance text-center text-muted-foreground sm:text-wrap sm:text-md">
                    Try GPTOrganizer now and simplify your workflow with powerful tools.
                </p>
                <Link to={"https://chromewebstore.google.com/detail/gptorganizer/nnjjgiijakdfccebgfeeceopgbdcflob"} target="_blank">
                    <Button className="h-12 font-semibold group cursor-pointer w-fit text-lg md:h-14 sm:px-10 rounded-md px-8 text-white">
                        <div className="flex items-center space-x-2">
                            <span>Add to Chrome</span>
                        </div>
                    </Button>
                </Link>
            </section>
            <Footer />
        </div>
        </>
  )
}
