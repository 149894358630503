import { BrowserRouter, Routes, Route } from "react-router-dom"
import LandingPage from "./LandingPage"
import Legal from "./Legal"
import { Analytics } from '@vercel/analytics/react';
import Policy from "./Policy"
import TermsOfService from "./Terms"
import PricingSection from "./Pricing"
import Sandbox from "./Sandbox"

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/pricing" element={<PricingSection />} />
          <Route path="/sandbox" element={<Sandbox />} />
        </Routes>
        <Analytics />
      </BrowserRouter>
  )
}