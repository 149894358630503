import { Link } from "react-router-dom"
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "./components/accordion"

export default function Faq() {
    return (
        <section className="flex flex-col items-center gap-6 py-24 px-10 sm:gap-7 w-full">
            <h2 className="max-w-4xl text-balance text-center font-heading text-3xl font-medium tracking-tighter sm:text-5xl">FAQ</h2>
            <p className="max-w-2xl text-balance text-center text-muted-foreground sm:text-wrap sm:text-md">
                Do you have another question? Ask us on <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank" className="font-bold cursor-pointer hover:underline">Discord</Link>
            </p>
                <Accordion type="single" collapsible className="mt-6 flex w-full max-w-3xl flex-col gap-4">
                <AccordionItem value="item-1">
                    <AccordionTrigger>Does this extension work with all versions of ChatGPT?</AccordionTrigger>
                    <AccordionContent>
                    The extension is compatible with all modern versions of ChatGPT. Make sure you're using the latest version of the tool to enjoy all the available features.
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2">
                    <AccordionTrigger>Can I share my folders or prompts with other users?</AccordionTrigger>
                    <AccordionContent>
                    Currently, the extension lets you save and organize your data personally, but sharing options may be added in future updates. Stay tuned for new features!
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-3">
                    <AccordionTrigger>Is my data secure?</AccordionTrigger>
                    <AccordionContent>
                    Yes, all your data is stored securely. We use standard security protocols to ensure that your personal information and prompts remain private and protected.
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-4">
                    <AccordionTrigger>Can I access my saved prompts from different devices?</AccordionTrigger>
                    <AccordionContent>
                    Yes, if you log in to your account on different devices, your saved prompts and folders will be synchronized automatically, allowing you to access them wherever you need them.
                    </AccordionContent>  
                </AccordionItem>
                <AccordionItem value="item-5">
                    <AccordionTrigger>Is there a free version of the extension?</AccordionTrigger>
                    <AccordionContent>
                    Yes, we offer a free version with basic functionality. For advanced features, such as unlimited folders or increased storage, you can opt for the premium version.
                    </AccordionContent>  
                </AccordionItem>
                </Accordion>
            </section>
    )
}