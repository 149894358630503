import { Link } from "react-router-dom"
import { Button } from "./components/button"
import { Menu } from "lucide-react";
import { useState, useEffect } from "react";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [browserName, setBrowserName] = useState('Browser');

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Firefox')) {
            setBrowserName('Firefox');
        } else {
            setBrowserName('Chrome');
        }
    }, []);

    return (
        <header className="sticky top-0 z-50 backdrop-blur-sm bg-background/80">
            <nav className="container mx-auto max-w-6xl py-5 px-5">
                <div className="flex items-center justify-between">
                <Link to={"/"}>
                    <img src="/gptorganizerlogo128.png" className="h-8" />
                </Link>

                {/* Menu Desktop */}
                <div className="hidden md:flex items-center space-x-4">
                    <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                        <Button size="default" variant="ghost">
                            Community
                        </Button>
                    </Link>
                    <Link to={"/pricing"}>
                        <Button size="default" variant="ghost">
                            Pricing
                        </Button>
                    </Link>
                    
                    {browserName === 'Firefox' ? (
                        <Link to={"https://addons.mozilla.org/firefox/addon/gptorganizer/"} target="_blank">
                            <Button className="text-white">Add to Firefox</Button>
                        </Link>
                    ) : (
                        <Link to={"https://chromewebstore.google.com/detail/gptorganizer/nnjjgiijakdfccebgfeeceopgbdcflob"} target="_blank">
                            <Button className="text-white">Add to Chrome</Button>
                        </Link>
                    )}
                </div>

                {/* Bouton Hamburger */}
                <Button 
                    variant="ghost" 
                    className="md:hidden"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <Menu />
                </Button>
                </div>

                {/* Menu Mobile */}
                {isMenuOpen && (
                <div className="md:hidden py-4 px-3 space-y-2">
                    <Link to={"https://discord.gg/sps49AECCk"} target='about:blank' className="block">
                        <Button size="default" variant="ghost" className="w-full">
                            Community
                        </Button>
                    </Link>
                    <Link to={"/pricing"} className="block">
                        <Button size="default" variant="ghost" className="w-full">
                            Pricing
                        </Button>
                    </Link>
                    
                    {browserName === 'Firefox' ? (
                        <Link to={"https://addons.mozilla.org/firefox/addon/gptorganizer/"} target="_blank">
                            <Button className="text-white">Add to Firefox</Button>
                        </Link>
                    ) : (
                        <Link to={"https://chromewebstore.google.com/detail/gptorganizer/nnjjgiijakdfccebgfeeceopgbdcflob"} target="_blank">
                            <Button className="text-white">Add to Chrome</Button>
                        </Link>
                    )}
                </div>
                )}
            </nav>
        </header>
    )
}