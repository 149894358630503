import { Link } from "react-router-dom"

export default function Footer() {
    return(

        <footer className="container mx-auto px-10 py-12 max-w-7xl">
            <div className="flex flex-col sm:flex-row justify-between">
                <div className="space-y-2 text-muted-foreground">
                <div className="">
                    <img src="/gptorganizerlogo128.png" className="h-8" />
                </div>
                <h3>
                    Join the community.
                </h3>
                <div className="flex space-x-4 pb-10">
                    <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank">
                        <svg fill="#7f8ea3" className="size-6" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z"/>
                        </svg>
                    </Link>
                    <Link to={"https://x.com/noshmaster"} target="about:blank">
                        <svg fill="#7f8ea3" className="size-6" width="800px" height="800px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.92,24.94A12.76,12.76,0,0,0,24.76,12.1c0-.2,0-.39,0-.59A9.4,9.4,0,0,0,27,9.18a9.31,9.31,0,0,1-2.59.71,4.56,4.56,0,0,0,2-2.5,8.89,8.89,0,0,1-2.86,1.1,4.52,4.52,0,0,0-7.7,4.11,12.79,12.79,0,0,1-9.3-4.71,4.51,4.51,0,0,0,1.4,6,4.47,4.47,0,0,1-2-.56v.05A4.53,4.53,0,0,0,9.5,17.83a4.53,4.53,0,0,1-2,.08A4.51,4.51,0,0,0,11.68,21,9.05,9.05,0,0,1,6.07,23,9.77,9.77,0,0,1,5,22.91a12.77,12.77,0,0,0,6.92,2" transform="translate(0)"/>
                        </svg>
                    </Link>
                </div>
                <p className="text-sm">
                    © 2024 GPTOrganizer. All rights reserved.
                </p>
                </div>
                <div className="flex gap-20">
                <div className="mt-10 flex basis-1/2 flex-col gap-5 sm:mt-0 sm:basis-auto">
                    <h3 className="text-lg font-semibold">
                        Community
                    </h3>
                    <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank" className="text-sm text-muted-foreground">
                        Discord
                    </Link>
                    <Link to={"https://x.com/noshmaster"} target="about:blank" className="text-sm text-muted-foreground">
                        Twitter
                    </Link>
                </div>
                <div className="mt-10 flex basis-1/2 flex-col gap-5 sm:mt-0 sm:basis-auto">
                    <h3 className="text-lg font-semibold">
                        Legal
                    </h3>
                    <Link to={"/terms"} className="text-sm text-muted-foreground">
                        Terms of Service
                    </Link>
                    <Link to={"/policy"} className="text-sm text-muted-foreground">
                        Privacy Policy
                    </Link>
                    <Link to={"/legal"} className="text-sm text-muted-foreground">
                        Legal
                    </Link>
                </div>
                </div>
            </div>
        </footer>
    )
}